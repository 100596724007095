import {
  ClipboardEvent,
  KeyboardEvent,
  FocusEvent,
  useRef,
  useState,
} from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { stripHtml } from "string-strip-html";
import { useSetRecoilState } from "recoil";

import {
  CLIP_COMMENT_CREATE,
  CLIP_COMMENT_FIELDS,
} from "MVVM/GraphQL/clipComments";
import { analytics } from "MVVM/Analytics";
import { useUser } from "MVVM/Hooks/useUser";
import { AllstarModalState } from "MVVM/State/modals";
import { ModalType } from "MVVM/@types";
import { MdArrowUpward } from "react-icons/md";

interface IClipCommentFormProps {
  clipId: string;
}

const MAX_COMMENT_SIZE = 600;

const ClipCommentForm = ({ clipId }: IClipCommentFormProps) => {
  const placeholder = "Add a comment...";
  const [comment, setComment] = useState(placeholder);
  const [disabled, setDisabled] = useState(true);
  const inputRef = useRef<HTMLElement | null>(null);
  const [createComment, { loading }] = useMutation(CLIP_COMMENT_CREATE);
  const { allstarUser } = useUser();
  const setAllstarModalState = useSetRecoilState(AllstarModalState);

  const handleFocus = (e: FocusEvent<HTMLDivElement>) => {
    if (!allstarUser.loggedIn) {
      e.target.blur();

      return setAllstarModalState({
        data: {
          action: "comment",
        },
        functions: {},
        isOpen: ModalType.Signup,
      });
    }

    setComment("");
    setDisabled(false);
  };

  const submit = (comment: string) => {
    createComment({
      variables: {
        clipId,
        text: comment,
      },
      onCompleted: () => {
        setComment("");
        if (inputRef.current) inputRef.current.innerHTML = "";

        analytics.track("Comment Created", {
          platform: "web",
        });
      },
      update(cache, { data: { commentCreate } }) {
        const newCommentRef = cache.writeFragment({
          data: commentCreate.comment,
          fragment: CLIP_COMMENT_FIELDS,
        });

        cache.modify({
          id: cache.identify({ __typename: "Clip", id: clipId }),
          fields: {
            comments(existingComments = {}) {
              const existingNodes = existingComments.nodes || [];

              return {
                ...existingComments,
                nodes: [newCommentRef, ...existingNodes],
              };
            },
          },
        });
      },
    });
  };

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();

    const text = e.clipboardData.getData("text/plain");
    const selection = window.getSelection();
    if (!selection) return;
    const range = selection.getRangeAt(0);
    const textNode = document.createTextNode(text);

    range.deleteContents();
    range.insertNode(textNode);
    range.setStartAfter(textNode);
    range.setEndAfter(textNode);

    selection.removeAllRanges();
    selection.addRange(range);
    setComment(text);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      validateComment();
    }
  };

  const handleClick = () => validateComment();

  const validateComment = () => {
    const strippedComment = stripHtml(comment).result;

    if (!allstarUser.loggedIn || /^\s*$/.test(strippedComment) || loading)
      return;

    submit(
      strippedComment.length > MAX_COMMENT_SIZE
        ? `${strippedComment.substring(0, MAX_COMMENT_SIZE)}...`
        : strippedComment,
    );
  };

  return (
    <ClipCommentFormWrapper>
      <InputWrapper loading={loading} onFocus={handleFocus}>
        <Input
          ref={inputRef}
          role="textbox"
          contentEditable={!loading}
          onKeyDown={handleKeyDown}
          aria-multiline="false"
          onInput={(e) => setComment(e.currentTarget.innerHTML)}
          onPaste={handlePaste}
          data-placeholder="Add a comment..."
        />
        <StyledButton
          onClick={disabled ? undefined : handleClick}
          disabled={disabled}
        >
          <StyledMdArrowUpward />
        </StyledButton>
      </InputWrapper>
    </ClipCommentFormWrapper>
  );
};

const InputWrapper = styled(Flex)<{ loading: boolean }>`
  background-color: ${({ theme }) => theme.colors.grey850};
  border-radius: 8px;
  border: none;
  color: ${(props) =>
    props.loading ? props.theme.colors.chalk : props.theme.colors.ice};
  cursor: ${(props) => (props.loading ? "not-allowed" : "text")};
  max-width: 100%;
  padding: 14px 16px;
  width: 100%;
`;

const Input = styled(Box)`
  align-self: center;
  display: block;
  flex-grow: 1;
  font-size: 16px;
  line-height: 1.4em;
  font-weight: 500;
  outline: none;

  :empty::before {
    color: ${({ theme }) => theme.colors.chalkAlpha80};
    content: attr(data-placeholder);
  }

  :empty:focus::before {
    content: "";
  }
`;

const StyledButton = styled(Flex)<{ disabled: boolean }>`
  align-items: center;
  align-self: flex-end;
  background: ${(props) =>
    props.disabled ? props.theme.colors.ned : props.theme.colors.envy};
  border-radius: 99px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  flex-grow: 0;
  flex-shrink: 0;
  height: 28px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  width: 28px;

  :hover {
    background: ${(props) =>
      props.disabled ? props.theme.colors.ned : props.theme.colors.envyAlpha80};
  }
`;

const StyledMdArrowUpward = styled(MdArrowUpward)`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.colors.ice};
`;

const ClipCommentFormWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.ned};
  border-top: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  bottom: 0;
  padding: 24px;
  position: sticky;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    position: static;
  }
`;

export { ClipCommentForm };
