import { Dispatch, SetStateAction } from "react";

import { TSortTypes } from "../Components/Pinnable/@types";
import { SVG_MAP } from "utils";

export type TClipsPageProps = { defaultView: EClipsViews; defaultGame: number };
export type TFilterCompProps = {
  game: number | string;
  sortedBy: string;
  query: IClipsQueryGQL;
  onQuery: (
    updatedValue: boolean | string | string[],
    indentifier: "search" | "status" | "filter" | "remixes",
  ) => Promise<any>;
  setSortedBy: Dispatch<SetStateAction<TSortTypes>>;
};

export type TTags = {
  displayType?: string;
  _id?: string;
  key?: string;
  category?: string;
  value?: string;
};

export interface IEffect {
  id: string;
  internalId: string;
  title: string;
  types: string;
}

export interface IUser {
  _id: string;
  avatar: string;
  avatarUrl: string;
  commentsDisabled?: boolean;
  username: string;
}

export interface IPlayer {
  avatar: string;
  displayName: string;
  user: IUser;
  gameId: string;
}

export interface IClip {
  canRequestPortrait: boolean;
  comments: TComment[];
  createdAt: string;
  effects: IEffect[];
  hasPortrait: boolean;
  id: string;
  internalId: string;
  isRemixable: boolean;
  players: IPlayer[];
  pointOfView: "OTHER" | "OWNER";
  portraitUrl: string;
  shareId: string;
  title: string;
  type: "CAREER_MONTAGE" | "MATCH_MONTAGE" | "SQUAD_MONTAGE" | "STANDARD";
  url: string;
  user: IUser;
  views: number;
  game: string;
  thumbnailStandardUrl: string;
  playerGameIdentifier: string;

  campaign: {
    isActive: boolean;
    marketingCopyClip: string;
  };

  remixOf: {
    user: IUser;
  };
}

export type TCreatorCard = {
  _id: string;
  scopeUse: string[];
  scopeVis: string[];
  effect: string;
  ui: {
    title: string;
    image: string;
    rarity: string;
    badge: string;
    category?: string;
    campaign: {
      campaignBlurbs: {
        creatorCard: {
          description: string;
          linkText: string;
          linkUrl: string;
          image: string;
          sponsorName: string;
        };
      };
    };
  };
  facet: {
    type: string[];
  };
};

export type TClipPersonalizations = {
  cards: TCreatorCard[];
};

export type TComment = {
  count: number;
  reaction: keyof typeof SVG_MAP;
};

export interface ICommentable {
  comments: TComment[];
}

export type TClip = {
  clipLink: string;
  cf: {
    hls: string;
    readyToStream: boolean;
    downloadUrl: string;
  };
  partner: {
    useCase: string;
    partnerId: {
      displayName: string;
      partnerId: string;
    };
  };
  _id: string;
  title: string;
  clipPreviewPath: string;
  views?: number;
  date: string;
  shareId: string;
  clipTitle: string;
  userClipTitle: string;
  status: string;
  createdDate: string;
  createdAt: string;
  clipImageThumb: string;
  payload?: unknown;
  mapName: string;
  steamId64: string;
  thumbnailStandardUrl: string;
  thumbnailOverlayUrl: string;
  onDemand: boolean;
  fromEdit?: boolean;
  score: {
    value: number;
  };
  username: string;
  game: number;
  remix: {
    isRemixed: boolean;
  };
  clipLengthSeconds: number;
  clipSnapshot: string;
  clipProperties: TTags[];
  personalizations: TClipPersonalizations;
  hasPortrait: boolean;
  portraitUrl: string;
  canRequestPortrait?: boolean;
  campaign: {
    campaignBlurbs: {
      clip: {
        text: string;
        linkText: string;
        linkUrl: string;
        image: string;
      };
    };
  };
  user: {
    _id: string;
    username: string;
    avatar?: string;
    discord?: {
      active: true;
      avatar?: string;
      discriminator: string;
      id: string;
    };
  };
  trackId: string;
};

export enum ModalType {
  ClipFull = "ClipFull",
  Credits = "Credits",
  DebugWindow = "DebugWindow",
  Delete = "Delete",
  DemoUploader = "DemoUploader",
  DisableConflictingCards = "DisableConflictingCards",
  Download = "Download",
  DuplicateUploadTikTok = "DuplicateUploadTikTok",
  DuplicateUploadYoutube = "DuplicateUploadYoutube",
  Feedback = "Feedback",
  LinkYourSocial = "LinkYourSocial",
  MatchHistoryWelcome = "MatchHistoryWelcome",
  MicroTransactions = "MicroTransactions",
  MobileOrDesktopUpload = "MobileOrDesktopUpload",
  MusicGenreSelector = "MusicGenreSelector",
  None = "None",
  Paywall = "Paywall",
  Remix = "Remix",
  Report = "Report",
  Share = "Share",
  SignatureSelector = "SignatureSelector",
  Signup = "Signup",
  SimpleClipViewer = "SimpleClipViewer",
  StudioWelcome = "StudioWelcome",
  TikTokUpload = "TikTokUpload",
  TransitTime = "TransitTime",
  Uploading = "Uploading",
  YoutubeUploadDetails = "YoutubeUploadDetails",
  SetRiotRegion = "SetRiotRegion",
}

export enum ESupportedGames {
  CS2 = 7302,
  CSGO = 730,
  DOTA2 = 570,
  FORTNITE = 407,
  LOL = 101,
  ALL = 0,
  NONE = "NONE",
}

export enum EGameSelectorHash {
  CS2 = "counter-strike-2",
  LOL = "league-of-legends",
  FORTNITE = "fortnite",
}

export type TGameFilterProps = {
  filterGame: (game: ESupportedGames) => void;
  game: ESupportedGames;
  dropdown?: boolean;
  handleResetToAllGames?: () => void;
};

export type TPaginatedClipFeed = {
  data: any[];
  dataCount: number;
  pageCount: number;
  currentPage: number;
  next: number;
  prev: number | null;
  totalPages: number | null;
  hasPrevPage: boolean;
  hasNextPage: boolean;
};

export interface IClipsQueryGQL {
  status?: string;
  filter?: string[];
  remixes?: boolean;
  user?: string;
  followers?: boolean;
  mobile?: boolean;
  search: string;
  game: number | string | null;
  page: number;
}

export type TStudioNavContent = {
  isVisible: boolean;
};

export type TFacetQueryType = {
  option2?: boolean;
  mechanic?: string[];
  type?: string[];
  behaviour?: string[];
  game?: string[];
  theme?: string[];
  proOnly?: boolean;
  myCards?: boolean;
};

export enum ERoute {
  Remix = "remix",
  Studio = "studio",
}

export enum EStudioTabView {
  Marketplace = "Marketplace",
  Collections = "Collections",
}

export enum EPriceTabView {
  Monthly = "Monthly",
  Annual = "Yearly",
}

export type TStudioProps = {
  modal: { type: string; data?: any };
  setModal: (value: { type: string; data?: any }) => void;
  clear: () => void;
  query: Record<string, any>;
  onQuery: (
    checked: boolean,
    indentifier: string,
    label: string,
    reset?: boolean,
  ) => void;
  handleCardClick: (card: any) => void;
  handleCardToggle: (card: TCreatorCard, isActive: boolean) => boolean;
  facetLoading: boolean;
  cardsData: Record<string, any>;
  cardClips: Record<string, any>;
  singleCardData: Record<string, any>;
  creatorCardModal: boolean;
  setCreatorCardModal: (value: boolean) => void;
  allstarUser: Record<string, any>;
  getFreshProfile: Record<string, any>;
  logout: Record<string, any>;
  banner: (value: string) => void;
  pageNav: string;
  mutateFollowers: () => void;
  remixData?: Record<string, any>;
  ownerInfo?: any;
  validateCardSelection?: any;
  clearEnabledCards?: any;
  isMobileApp?: boolean;
  handleToggleAll?: (
    isActive: boolean,
    category?: string,
    cardIds?: string[],
  ) => boolean;
};

export type TClipsTypeSelectorProps = {
  isProfile: boolean;
  view: EClipsViews;
  handleViewChange: (tabView: EClipsViews) => void;
};

export enum EClipsViews {
  "Clips" = "Clips",
  "Mobile" = "Mobile Clips",
  "Following" = "Following",
  "Montages" = "Montages",
}

export enum EPartnerNames {
  "Gamersclub" = "Gamersclub",
  "Scopegg" = "SCOPE.GG",
  "Esportal" = "Esportal",
}

export enum ETiers {
  "Mythic" = 500,
  "Legendary" = 400,
  "Rare" = 300,
  "Premium" = 200,
  "Standard" = 100,
}

export enum ECrossSitePromotionType {
  FirstLandTakeover = "FirstLandTakeover",
  SideRailAd = "SideRailAd",
  VideoPlayerCTA = "VideoPlayerCTA",
  CarouselSlide = "CarouselSlide",
  TopSiteBanner = "TopSiteBanner",
}

export interface ICreatorCard {
  _id: string;
  ui: IUi;
  facet: IFacet;
  cc: ICloudCapture;
  scopeUse: TScopeVisUse[];
  scopeVis: TScopeVisUse[];
  linkedCards: any[];
  blockedCards: any[];
  blockedEffects: any[];
  skipSuggested: boolean;
  unlimited: boolean;
  isActive: boolean;
  motive: string;
  effect: string;
  cost: number;
  totalUses: number;
  isBackgroundCard: boolean;
  updated: string;
  createdDate: string;
  __v: number;
  studioVisibility: boolean;
  tier: "S" | "A" | "B" | "C" | "D";
}

export type TScopeVisUse =
  | "FREE"
  | "TRIAL"
  | "PRO"
  | "PRO_PLUS"
  | "CREATOR_PLAT"
  | "INTERNAL"
  | "OG"
  | "SUPERSTAR";

export interface ICloudCapture {
  src: string;
  model: string;
  cfgName: string;
  amsBPM: number;
  mixWeight: number;
  weight: {
    slot: "INTRO" | "PRE" | "POST" | "OUTRO";
    chance: "HIGH" | "MED" | "LOW" | "NONE";
  }[];
  "9x16src": string;
  sfx: ISoundEffects;
  reshade:
    | "OVERLAY"
    | "SKYBOX"
    | "EFFECT"
    | "FILTER"
    | "FIRST"
    | "SECOND"
    | "LETTERBOX";
  mute: IMute;
}

export interface IMute {
  condition: string;
}

export interface ISoundEffects {
  game: "CS:GO" | "DOTA" | "LEAGUE" | "FORTNITE"[];
  trigger:
    | "KILL"
    | "DEATH"
    | "MULTI_KILL"
    | "TEAM_KILL"
    | "BOMB_DEFUSAL"
    | "FAILED_BOMB_DEFUSAL"
    | "KNIFE_KILL"
    | "ZUES_KILL"
    | "HE_GRENADE_KILL"[];
}

export interface IFacet {
  type: string[];
  game: string[];
  theme: string[];
  series: string[];
  mechanic: any[];
  behaviour: any[];
}

export type TSupportedGames = 730 | 7302 | 570 | 101 | 407;
export interface IUi {
  game: TSupportedGames;
  slug: string;
  uses: string;
  cost: number;
  games: TSupportedGames[];
  badge: string;
  title: string;
  order: number;
  image: string;
  rarity: 100 | 200 | 300 | 400 | 500;
  useType: string;
  disabled: boolean;
  category:
    | "TIME"
    | "MUSIC"
    | "AUDIO"
    | "FILTER"
    | "KILL"
    | "INTRO"
    | "OVERLAY"
    | "SOCIAL"
    | "TRANSITIONS"
    | "MEMES";
  flavorText: string;
  hoverEffect: "gif" | "audio" | "video" | "lightbox" | "static" | "swiper";
  explanation: string;
  hoverEffectSrc: string;
  hoverEffectSrcBefore: string;
}

export enum EClipPageSource {
  DIRECT = "Direct",
  FEED = "Feed",
  MODAL = "Modal",
}
